export default {
  namespaced: true,
  state: {
    lineOa: (localStorage.getItem('adx-line-oa') !== null) ? localStorage.getItem('adx-line-oa') : '',
  },
  getters: {},
  mutations: {
    UPDATE_LINE_OA(state, val) {
      localStorage.setItem('adx-line-oa', val)
      state.lineOa = val
    },
  },
  actions: {},
}
