/* eslint-disable */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
import Vue from 'vue'

import enLocaleElement from 'element-ui/lib/locale/lang/en'
import thLocaleElement from 'element-ui/lib/locale/lang/th'
import locale from 'element-ui/lib/locale'

import enLocale from '@/lang/en-US'
import thLocale from '@/lang/th-TH'
import enLocale_render from '@/lang/en-US_render'
import thLocale_render from '@/lang/th-TH_render'
import enLocale_extension from '@/lang/en-US_extension'
import thLocale_extension from '@/lang/th-TH_extension'
import si18n from './smart-vue-i18n/index'

const langResources = {
  'th-TH': {
    something: {
      // ...
    },
    ...thLocaleElement,
    ...thLocale,
    ...thLocale_render,
    ...thLocale_extension,
  },
  'en-US': {
    something: {
      // ...
    },
    ...enLocaleElement,
    ...enLocale,
    ...enLocale_render,
    ...enLocale_extension,
  },
}

// *********************  下述代码参考element-ui/lib/locale/format.js begin  *****************//

const RE_NARGS = /(%|)\{([0-9a-zA-Z_]+)\}/g
function hasOwn(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

const elLocalFormatter = function template(string, args) {
  return string.replace(RE_NARGS, (match, prefix, i, index) => {
    let result

    if (string[index - 1] === '{'
        && string[index + match.length] === '}') {
      return i
    }
    result = hasOwn(args, i) ? args[i] : null
    if (result === null || result === undefined) {
      return ''
    }

    return result
  })
}

// *********************  下述代码参考element-ui/lib/locale/format.js end  ******************//

Vue.use(si18n, {
  lang: localStorage.getItem('liff_form_locale') || 'th-TH',
  messages: langResources,
})

locale.i18n((key, value) => {
  const result = Vue.prototype.$st(key)
  return elLocalFormatter(result, value)
})

export const changeLocale = function (langName) {
  Vue.prototype.$si18n.setLang(langName)
  localStorage.setItem('liff_form_locale', langName)
}

export const translate = function (key) {
  return Vue.prototype.$st(key)
}

export default {
  methods: {
    i18nt(key) {
      return this.$st(key)
    },

    /* 如果key1不存在，则查找key2 */
    i18n2t(key1, key2) {
      return this.$st2(key1, key2)
    },

  },
}
