import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import en from 'vee-validate/dist/locale/en.json'
// import axios from '@axios'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const messages = {}

  // axios
  //   .post('/center/locale_message', {})
  //   .then(response => {
  //     messages.th = response.data.th
  //     messages.en = response.data.en
  //     // console.log('done message')
  //     // this.$i18n.setLocaleMessage('th', messages.th.default)
  //   })
  // .catch(() => {
  //   console.log('error')
  // })

  // if (messages.th === undefined) {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  // }

  return messages
}
export default new VueI18n({
  locale: 'th',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
