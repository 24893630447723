export default {
  // Endpoints
  loginEndpoint: '/center/login',
  registerEndpoint: '/center/register',
  refreshEndpoint: '/center/refreshtoken',
  logoutEndpoint: '/center/logout',
  forgotEndpoint: '/center/forgot',
  verifyEmailEndpoint: '/center/verify_email',
  getEmailVerityEndpoint: '/center/get_email_verify',
  resetPasswordEndpoint: '/center/reset_password',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
