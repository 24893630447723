<template>
  <div
    class="container-wrapper"
    :class="[customClass]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContainerItemWrapper',
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customClass() {
      return this.widget.options.customClass ? this.widget.options.customClass.join(' ') : ''
    },

  },
}
</script>

<style lang="scss" scoped>

</style>
