/* eslint-disable array-callback-return */
const requireComponent = require.context('./', false, /\w+\.vue$/)

const comps = {}

requireComponent.keys().map(fileName => {
  const comp = requireComponent(fileName).default
  comps[comp.name] = comp
})

export default comps
