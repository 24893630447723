import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: (localStorage.getItem('adx-menu-collapsed') === 'true') || $themeConfig.layout.menu.isCollapsed,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      localStorage.setItem('adx-menu-collapsed', val)
      state.isVerticalMenuCollapsed = val
    },
  },
  actions: {},
}
