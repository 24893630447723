import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import VJsoneditor from 'v-jsoneditor/src/index'

// drag and drop
import vueDraggable from 'vuedraggable/src/vuedraggable'

// code editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import 'prismjs/themes/prism-tomorrow.css'

Vue.use(VJsoneditor)

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('vue-draggable', vueDraggable)
Vue.component('PrismEditor', PrismEditor)
