export default [
  // line
  {
    path: '/line-oa',
    name: 'line-oa',
    component: () => import('@/views/line/oa/Oa.vue'),
    meta: {
      layout: 'header',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'line-oa',
    },
  },
  // line-user-account
  {
    path: '/line-user-account',
    name: 'line-user-account',
    component: () => import('@/views/center/user-account/UserAccount.vue'),
    meta: {
      layout: 'header',
    },
  },
]
